<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="createCustomer()">
          <div class="card-header">
            <h4>Create Customer</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Customer Name</label>
                  <input type="text" class="form-control" v-model="fields.customer_name" placeholder="Christopher Okokon"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Phone</label>
                  <input type="text" class="form-control" v-model="fields.phone" placeholder="0700 000 0000"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Customer type</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.customer_type">
                      <option value="" disabled>Select an option</option>
                      <option value="existing">Existing Customer</option>
                      <option value="prospect">Prospective Customer</option>
                      <option value="agent">Agent</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="fields.customer_type == 'existing'">
              <div class="col">
                <div class="form-group">
                  <label>Serial Number</label>
                  <input type="text" class="form-control" v-model="fields.serial_number" placeholder="DVA2374929923"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group mb-0">
                  <label>State</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.state">
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="state in states"
                        :value="state.alias"
                        :key="state.alias"
                      >
                        {{ state.state }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <label>Local Government Area</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.lga">
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="lga in selectedLGA"
                        :value="lga"
                        :key="lga"
                      >
                        {{ lga }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "createCustomer",
  computed: {
    ...mapState(["loading"]),
    selectedLGA() {
      let results = this.states.find(
        (state) => state.alias === this.fields.state
      );
      return results ? this.$globalFunc.sortBy(results.lgas) : [];
    },
  },
  mounted() {},
  data() {
    return {
      states: this.$globalFunc.locations(),
      fields: {
        customer_type: "",
        state: "",
        lga: "",
      },
    };
  },
  methods: {
    async createCustomer() {
      if (this.validateData()) {
        const response = await GeneralHelpers.createCustomer(this.fields);
        if (response.status) {
          this.showAlert("Success", "Customer has been created successfully", "success")
          this.$router.push({
            name: "All Customers"
          })
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.customer_name) {
        if (this.fields.phone) {
          if (this.fields.state) {
            if (this.fields.lga) {
              if (this.fields.customer_type) {
                if (this.fields.customer_type !== 'existing') {
                  return true
                } else {
                  if (this.fields.serial_number) {
                    return true
                  } else {
                    this.showAlert(
                      "Validation error",
                      "Kindly provide customer serial number",
                      "warning"
                    );
                  }
                }
              } else {
                this.showAlert(
                  "Validation error",
                  "Select the customer type",
                  "warning"
                );
              }
            } else {
              this.showAlert(
                "Validation error",
                "Select a customer lga",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Select a customer state",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Provide a phone number",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Provide the customer\'s name",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>