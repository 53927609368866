import Api from "@/services/Api";

export default {
  getUsersByType(type) {
    return Api().get(`/support/admin/usercategory/${type}`);
  },
  getCustomersByType(type) {
    return Api().get(`/admin/customers/category/${type}`);
  },
  deleteCustomersById(customerId) {
    return Api().delete(`/admin/customers/${customerId}`);
  },
  createCustomer(payload) {
    return Api().post("/admin/customers", payload);
  },
  getIssues() {
    return Api().get("/support/issues");
  },
  getDashboardStats() {
    return Api().get("/support/dashboard");
  },
  createIssue(payload) {
    return Api().post("/support/issues", payload);
  },
  changePassword(payload) {
    return Api().post("/admin/change/password", payload);
  },
  deleteIssuesById(issueId) {
    return Api().delete(`/support/issues/${issueId}`);
  },
};